const cookieExists = name => document.cookie.split(';').some(c => c.trim().startsWith(`${name }=`))

const deleteCookie = (name, path, domain) => {
  if (cookieExists(name)) {
    document.cookie = `${name}=${path ? `;path=${path}` : ''
    }${domain ? `;domain=${domain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`
  }
}

const getCookieValue = name => document.cookie.split('; ').reduce((r, v) => {
  const parts = v.split('=')
  return parts[0] === name ? decodeURIComponent(parts[1]) : r
}, '')

export {
  cookieExists,
  deleteCookie,
  getCookieValue,
}
