<template>
  <div id="app">
    <StartPage v-if="isLoggedIn" />
    <LoginPage
      v-else
      :error="error"
    />
  </div>
</template>

<script>
import LoginPage from './components/LoginPage.vue'
import StartPage from './components/StartPage.vue'
import { getCookieValue } from './utilities/cookieHandler'
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    LoginPage,
    StartPage,
  },

  data() {
    return {
      config: {
        logo: '/static/logo.svg',
      },
      cookieValue: '',
      error: false,
      toasterPosition: 'b-toaster-top-center',
    }
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },

  created() {
    // Add a request interceptor
    this.axios.interceptors.request.use(config => {
      this.cookieValue = getCookieValue('auth')
      // Do something before request is sent
      if (this.isLoggedIn) {
        config.headers.Auth = this.cookieValue
      }
      return config
    }, error => {
      Promise.reject(error)
    })

    // Add a response interceptor
    this.axios.interceptors.response.use(response => {
      if (response.headers['x-auth-token']) {
        document.cookie = `auth=${response.headers['x-auth-token']}`
      }
      return response
    }, error => {
      if (error.response && error.response.status === 403) {
        this.error = true
        this.$router.push({ name: 'Login' })
      }
      return Promise.reject(error)
    })
  },
}
</script>
