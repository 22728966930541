import storeContent from './modules/content'
import storeAuth from './modules/auth'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth: storeAuth,
    content: storeContent,
  },
})

export default store
