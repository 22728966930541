import '@babel/polyfill'
import 'mutationobserver-shim'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/scss/custom.scss'

import axios from 'axios'
import App from './App.vue'
import messages from './i18n/translations'
import router from './router/'
import store from './store/'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueI18n)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.prototype.$logoURL = process.env.VUE_APP_LOGO_URL

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_XHR_PREFIX

const localStorageAvailable = typeof localStorage !== 'undefined'
const browserLanguage = navigator.language.split('-')[0]

const languages = {
  available: ['de', 'en'],
  fallback: 'de',
  names: {
    de: 'Deutsch',
    en: 'English',
  },
}

Vue.prototype.$localStorageAvailable = localStorageAvailable
Vue.prototype.$languages = languages

let language = languages.available.indexOf(browserLanguage) > -1 ? browserLanguage : languages.fallback

if (localStorageAvailable && localStorage.language && languages.available.indexOf(localStorage.language) > -1) {
  language = localStorage.language
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: language,
  messages,
})

new Vue({
  render: h => h(App),
  i18n,
  router,
  store,
}).$mount('#app')

Vue.prototype.$toastErrorConfig = {
  autoHideDelay: 5000,
  solid: true,
  toaster: 'b-toaster-top-center',
  variant: 'danger',
}

Vue.prototype.$toastSuccessConfig = {
  autoHideDelay: 3000,
  solid: true,
  toaster: 'b-toaster-top-center',
  variant: 'success',
}
