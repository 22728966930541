<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner variant="primary" />
    </div>
    <div
      v-if="rows === 0"
      class="text-center p-3"
    >
      {{ $t('no_pages_created') }}
    </div>
    <b-list-group
      v-else
      v-b-scrollspy:listgroup
      flush
      class="mb-3"
    >
      <b-list-group-item
        v-for="page in paginatedItems"
        :key="page.id"
        :active="page.name === selectedFile"
        @click="clickEdit(page)"
      >
        <span>
          {{ page.info.title }}
        </span>
        <br>
        <small
          v-if="page.info.date"
          class="text-muted"
        >
          {{ $t('published') }} {{ new Date(page.info.date).toLocaleString() }}
        </small>
      </b-list-group-item>
    </b-list-group>

    <b-pagination
      v-if="rows/perPage > 1"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="center"
      first-number
      last-number
      variant="info"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ListPages',

  props: {
    path: {
      'default': '',
      'type': String,
    },
  },

  data() {
    return {
      currentPage: 1,
      loading: true,
      perPage: 10,
      selectedFile: '',
    }
  },

  computed: {
    ...mapGetters({
      filteredPages: 'content/filteredPages',
    }),

    currentFile() {
      return this.$route.params.file
    },

    paginatedItems() {
      const items = this.filteredPages

      return items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      )
    },

    rows() {
      return this.filteredPages.length
    },
  },

  watch: {
    path(folder) {
      this.loading = true
      this.fetchPagesFromFolder(folder).then(() => this.loading = false)
    },

    '$route.params.file'(to, from) {
      this.selectedFile = to
    },
  },

  created() {
    this.fetchPagesFromFolder(this.path).then(() => {
      this.loading = false
      setTimeout(() => this.selectedFile = this.currentFile, 100)
    })
  },

  methods: {
    ...mapActions({
      fetchPagesFromFolder: 'content/fetchPagesFromFolder',
    }),

    clickEdit(page) {
      this.$emit('selected-page-id', page.id)

      if (this.currentFile !== page.name) {
        this.$router.push({
          name: 'PagesOverview',
          params: { path: this.path, file: page.name },
          query: { lang: page.lang || undefined },
          hash: '#editor',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group-item {
  cursor: pointer;

  &.active {
    cursor: default;
    pointer-events: none;

    .text-muted {
      color: #d6d8db !important;
    }
  }
}
</style>
