import axios from 'axios'
import Vue from 'vue'

export default {
  state: {
    folders: {},
    pages: {},
    schema: {},
    uploadedFiles: {},
  },

  getters: {
    folders: state => Object.values(state.folders),
    filteredPages: state => Object.values(state.pages).filter(page => !page.id.match(/[a-z0-9-_]+\.[a-z]{2}\.md/g) && page.name !== '_index'),
    pages: state => Object.values(state.pages),
    uploadedFiles: state => Object.values(state.uploadedFiles),
  },

  actions: {
    createPage({ dispatch }, PayloadData) {
      return new Promise((resolve, reject) => {
        axios.post('/create', PayloadData, { responseType: 'json' }).then(async () => {
          await dispatch('fetchPagesFromFolder', PayloadData.path)
          resolve(true)
        })
          .catch(error => {
            console.log('Create Page', error)
            reject(error)
          })
      })
    },

    deletePage({ commit }, pageId) {
      return new Promise((resolve, reject) => {
        axios.post('/delete', { ID: pageId }, { responseType: 'json' }).then(() => {
          commit('deletePage', pageId)
          resolve(true)
        })
          .catch(error => {
            console.log('Delete Page', error)
            reject(error)
          })
      })
    },

    deleteUploadedFile({ commit }, uri) {
      return new Promise((resolve, reject) => {
        axios.post('/deleteUpload', { ID: uri }, { responseType: 'json' }).then(() => {
          commit('deleteUploadedFile', uri)
          resolve(true)
        })
          .catch(error => {
            console.log('Delete File', error)
            reject(error)
          })
      })
    },

    editPage({ commit }, PayloadData) {
      return new Promise((resolve, reject) => {
        axios.post('/edit', PayloadData, { responseType: 'json' }).then(response => {
          commit('updatePage', { pageId: PayloadData.id, content: PayloadData })
          resolve(response)
        })
          .catch(error => {
            console.log('Edit Page', error)
            reject(error)
          })
      })
    },

    fetchFolders({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/listFolders')
          .then(response => {
            const folders = response.data[0].children
            commit('setFolders', folders)
            resolve(true)
          })
          .catch(error => {
            console.log('Fetch Folders', error)
            reject(error)
          })
      })
    },

    fetchPagesFromFolder({ commit }, folder) {
      return new Promise((resolve, reject) => {
        axios.get(`/listFolder/${ folder }`)
          .then(response => {
            const pages = response.data
            commit('setPages', pages)
            resolve(true)
          })
          .catch(error => {
            console.log(`Fetch Pages from Folder ${folder}`, error)
            reject(error)
          })
      })
    },

    fetchUploadedFiles({ commit }, pageId) {
      return new Promise((resolve, reject) => {
        axios.post('/uploaded', { ID: pageId }, { responseType: 'json' })
          .then(response => {
            let files = response.data || []
            if (files) {
              files = files.map(img => ({ ...img, thumbnailUrl: `${ process.env.VUE_APP_PREVIEW_PREFIX }${img.thumbnail}`, url: `${ process.env.VUE_APP_PREVIEW_PREFIX }${img.uri}` }))
            }

            commit('setUploadedFiles', files)
            resolve(true)
          })
          .catch(error => {
            console.log('Fetch Uploaded Files', error)
            reject(error)
          })
      })
    },

    getPageById({ state }, id) {
      const index = Object.values(state.pages).map(p => p.id)
        .indexOf(id)
      return state.pages[index]
    },

    getSchemaFromFolder({ commit }, folder) {
      return new Promise((resolve, reject) => {
        axios.get(`/getSchema/${folder}`)
          .then(response => {
            const schema = response.data
            commit('setSchema', schema)
            resolve(schema)
          })
          .catch(error => {
            console.log(`Fetch Schema from Folder ${folder}`, error)
            reject(error)
          })
      })
    },

    uploadFile(_, formData) {
      return new Promise((resolve, reject) => {
        axios.post('/upload', formData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log('Upload File(s)', error)
            reject(error)
          })
      })
    },
  },

  mutations: {
    createPage(state, page) {
      const index = Object.values(state.pages).length
      state.pages = {
        ...state.pages,
        [index]: page,
      }
    },

    deletePage(state, pageId) {
      const index = Object.values(state.pages).map(p => p.id)
        .indexOf(pageId)
      Vue.delete(state.pages, index)
    },

    deleteUploadedFile(state, uri) {
      const index = Object.values(state.uploadedFiles).map(f => f.uri)
        .indexOf(uri)
      Vue.delete(state.uploadedFiles, index)
    },

    setFolders(state, folders) {
      state.folders = {
        ...folders,
      }
    },

    setPages(state, pages) {
      state.pages = {
        ...pages,
      }
    },

    setSchema(state, schema) {
      state.schema = {
        ...schema,
      }
    },

    setUploadedFiles(state, files) {
      state.uploadedFiles = {
        ...files,
      }
    },

    updatePage(state, { pageId, content }) {
      const index = Object.values(state.pages).map(p => p.id)
        .indexOf(pageId)
      state.pages[index] = {
        ...state.pages[index],
        ...content,
      }
    },
  },

  namespaced: true,
}
