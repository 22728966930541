<template>
  <div>
    <navbar />

    <div class="row no-gutters align-items-stretch mt-3">
      <div class="col-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import navbar from './common/layout/navbar.vue'

export default {
  name: 'StartPage',
  components: {
    navbar,
  },
}
</script>
