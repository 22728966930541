<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        :label="$t('new_password')"
        label-for="input-password"
      >
        <b-input-group>
          <b-form-input
            v-model="form.password"
            :type="form.passwordHidden ? 'password' : 'text'"
            required
          />
          <b-input-group-append>
            <b-button
              variant="light"
              @click="form.passwordHidden = !form.passwordHidden"
            >
              <b-icon :icon="form.passwordHidden ? 'eye' : 'eye-slash'" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group
        :label="$t('new_password_repeat')"
        label-for="input-password-repeat"
      >
        <b-input-group>
          <b-form-input
            v-model="form.passwordRepeat"
            :type="form.passwordHidden ? 'password' : 'text'"
            required
          />
          <b-input-group-append>
            <b-button
              variant="light"
              @click="form.passwordHidden = !form.passwordHidden"
            >
              <b-icon :icon="form.passwordHidden ? 'eye' : 'eye-slash'" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-button
        type="submit"
        variant="primary"
      >
        {{ $t('change_password') }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ChangePassword',
  data() {
    return {
      form: {
        password: '',
        passwordHidden: true,
        passwordRepeat: '',
      },
    }
  },
  methods: {
    ...mapActions({
      changePassword: 'auth/changePassword',
    }),

    onSubmit() {
      if (this.form.password.trim() === '' || this.form.password.trim() !== this.form.passwordRepeat.trim()) {
        this.$bvToast.toast(this.$t('notification.error.check_password'), {
          title: this.$t('notification.title.error'),
          ...this.$toastErrorConfig,
        })
        return
      }

      this.changePassword(this.form.password).then(() => {
        this.$bvToast.toast(this.$t('notification.success.change_password'), {
          title: this.$t('notification.title.success'),
          ...this.$toastSuccessConfig,
        })

        this.password = ''
        this.passwordRepeat = ''
      })
        .catch(error => {
          this.$bvToast.toast(this.$t('notification.error.change_password', { error }), {
            title: this.$t('notification.title.error'),
            ...this.$toastErrorConfig,
          })
        })
    },
  },
}
</script>
