<template>
  <div>
    <div class="row no-gutters">
      <div class="col-12">
        <button
          class="btn btn-primary mx-3 float-right"
          @click="clickAdd"
        >
          {{ $t('add_page') }}
        </button>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-lg-5 p-3">
        <b-card no-body>
          <button
            :class="['btn btn-secondary d-lg-none', {'collapsed': showList}]"
            @click="showList = !showList"
          >
            {{ showList ? $t('page_list_hide') : $t('page_list_show') }}
            <b-icon
              :icon="showList ? 'chevron-up' : 'chevron-down'"
              class="ml-2"
            />
          </button>
          <b-collapse
            id="collapseList"
            v-model="showList"
          >
            <b-card
              no-body
              header-tag="header"
            >
              <template #header>
                <h5 class="mb-0">
                  {{ $t('current_path', { path }) }}
                </h5>
              </template>
              <ListPages
                :path="path"
                @selected-page-id="id => selectPageId(id)"
              />
            </b-card>
          </b-collapse>
        </b-card>
      </div>
      <div class="col-12 col-lg-7 py-lg-3 pr-lg-3 p-3">
        <b-card
          id="listgroup"
          class="h-100"
        >
          <template v-if="editMode">
            <EditPage
              :mode="mode"
              :selected-page-id="selectedPageId"
              @page-created="page => updateOnCreate(page)"
              @page-deleted="updateOnDelete"
              @selected-page-id="id => selectPageId(id)"
            />
          </template>
          <div
            v-else
            class="d-flex h-100 align-items-center"
          >
            <p class="text-muted mx-auto mb-0">
              {{ $t('no_page_selected') }}
            </p>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import EditPage from './EditPage.vue'
import ListPages from './ListPages.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PagesOverview',

  components: {
    ListPages,
    EditPage,
  },

  props: {
    path: {
      'type': String,
      'default': '',
    },
  },

  data() {
    return {
      editMode: false,
      mode: 'edit',
      selectedPageId: '',
      showList: true,
    }
  },

  computed: {
    ...mapGetters({
      pages: 'content/pages',
    }),

    currentFile() {
      return this.$route.params.file
    },
  },

  watch: {
    path() {
      this.editMode = false
    },
  },

  created() {
    if (this.currentFile) {
      this.editMode = true
    }
  },

  methods: {
    clickAdd() {
      this.selectedPageId = ''
      this.editMode = true
      this.mode = 'new'

      if (this.currentFile !== 'new') {
        this.$router.push({
          name: 'PagesOverview',
          params: { path: this.path, file: 'new' },
          hash: '#editor',
        })
      }
    },

    selectPageId(id) {
      this.selectedPageId = id
      this.editMode = true
      if (this.currentFile !== 'new') {
        this.mode = 'edit'
      }
    },

    updateOnCreate(page) {
      this.selectPageId(page.id)

      if (this.currentFile !== page.name) {
        this.$router.push({
          name: 'PagesOverview',
          params: { path: this.path, file: page.name },
          query: { lang: page.lang || undefined },
          hash: '#editor',
        })
      }

      this.$bvToast.toast(this.$t('notification.success.create_page'), {
        title: this.$t('notification.title.success'),
        ...this.$toastSuccessConfig,
      })
    },

    updateOnDelete() {
      this.selectedPageId = ''
      this.editMode = false
      this.$router.push({ name: 'PagesOverview', params: { path: this.path } })

      this.$bvToast.toast(this.$t('notification.success.delete_page'), {
        title: this.$t('notification.title.success'),
        ...this.$toastSuccessConfig,
      })
    },
  },
}
</script>
