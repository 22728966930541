<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <b-spinner variant="primary" />
  </div>
  <div
    v-else
    id="editor"
  >
    <b-form
      id="CustomForm"
      ref="customForm"
      @submit.prevent="handleSubmit"
    >
      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group
            :label="$t('page_editor.language_select')"
            label-class="font-weight-bold"
          >
            <b-form-select
              v-model="selectedLanguage"
              required
              :options="templateLanguages"
              @change="checkForExistingFile"
            />
          </b-form-group>
        </div>
      </div>

      <b-form-group>
        <div class="row my-2 mb-3">
          <div class="col-12 col-md-5">
            <b-form-checkbox
              v-model="page.info.draft"
              class="d-inline"
            >
              {{ $t('page_editor.draft') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-if="!editMode"
              v-model="page.post"
              class="d-inline ml-4"
            >
              {{ $t('page_editor.blog_post') }}
            </b-form-checkbox>
          </div>
          <div class="col-12 col-md-7 text-md-right">
            <small>
              {{ $t("page_editor.filename") }}: {{ page.name }}
            </small>
          </div>
        </div>

        <custom-form
          :schema="schema"
          :form-data="page.info"
          :mode="mode"
          @update="(key, val) => updateValue(key, val)"
        />

        <label class="d-block font-weight-bold">Content</label>
        <vue-easymde
          ref="markdownEditor"
          v-model="page.content"
          :configs="editor_config"
        />
      </b-form-group>
    </b-form>

    <ul
      v-if="uploadedFiles.length > 0 && editMode"
      class="list-unstyled upload-files"
    >
      <li
        v-for="item in uploadedFiles"
        :key="item.uri"
        class="d-inline-block align-top"
      >
        <img
          :src="item.thumbnailUrl || item.url"
          class="img-responsive img-thumbnail"
          :alt="item.name"
          :title="item.name"
          @click="insertImageTag(item)"
        >
        <b-button
          class="image-delete"
          size="sm"
          variant="danger"
          @click="deleteImage(item.uri)"
        >
          <b-icon icon="trash" />
        </b-button>
      </li>
    </ul>

    <small
      v-if="uploadedFiles.length > 0 && editMode"
      class="d-block text-center"
    >
      {{ $t('page_editor.insert_image') }}
    </small>
    <form
      v-if="editMode"
      enctype="multipart/form-data"
      novalidate
    >
      <hr>
      <div class="dropbox mb-2">
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving"
          accept="image/*"
          class="input-file"
          @change="filesAppendFormData($event.target.name, $event.target.files)"
        >
        <p class="mb-0 px-2 lead">
          {{ isSaving ? $t('page_editor.upload_progress', { uploadFileCount }) : $t('page_editor.upload_info') }}
        </p>
      </div>
    </form>

    <div class="d-flex justify-content-between mt-3">
      <b-button
        :disabled="!editMode"
        variant="danger"
        @click="deletePage(page.id)"
      >
        {{ $t('page_editor.delete') }}
      </b-button>

      <div class="d-flex justify-content-end">
        <b-button
          class="mr-3"
          target="_blank"
          :href="previewLink"
          :disabled="!editMode"
        >
          {{ $t('page_editor.preview') }}
        </b-button>
        <b-button
          form="CustomForm"
          type="submit"
          variant="primary"
        >
          {{ editMode ? $t('page_editor.publish') : $t('page_editor.create_page') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomForm from '../forms/CustomForm.vue'
import VueEasymde from 'vue-easymde'
import 'easymde/dist/easymde.min.css'
import { mapActions, mapGetters } from 'vuex'

const DEFAULT_TEMPLATE_LANG = process.env.VUE_APP_DEFAULT_TEMPLATE_LANG

export default {
  name: 'EditPage',

  components: {
    CustomForm,
    VueEasymde,
  },

  props: {
    mode: {
      'type': String,
      'default': 'edit',
      'validator' (value) {
        return ['new', 'edit'].indexOf(value) !== -1
      },
    },

    selectedPageId: {
      'type': String,
      'default': '',
    },
  },

  data() {
    return {
      editMode: false,
      editor_config: {
        forceSync: true,
        spellChecker: false,
        toolbar: ['bold', 'italic', 'strikethrough', 'heading', '|', 'code', 'quote', '|', 'unordered-list', 'ordered-list', 'clean-block', '|', 'link', 'image', 'horizontal-rule', '|', 'preview', 'side-by-side', 'fullscreen', '|', 'guide'],
        insertTexts: {
          horizontalRule: ['', '\n-----\n'],
          image: ['{{< fig src="#url#" title="" alt="" link="" >}}', ''],
          link: ['[', '](#url#)'],
          table: ['', '\n\n| Column 1 | Column 2 | Column 3 |\n| -------- | -------- | -------- |\n| Text     | Text     | Text     |\n\n'],
        },
        renderingConfig: {
          singleLineBreaks: false,
          codeSyntaxHighlighting: true,
        },
      },

      isSaving: false,
      loading: true,

      page: {
        id: '',
        name: '',
        lang: '',
        content: '',
        post: true,
        info: {},
      },

      previewLink: `${process.env.VUE_APP_PREVIEW_PREFIX}/`,
      schema: null,
      selectedLanguage: DEFAULT_TEMPLATE_LANG,
      templateLanguages: [
        { text: this.$t('page_editor.language_options.en'), value: 'en' },
        { text: this.$t('page_editor.language_options.de'), value: 'de' },
      ],

      uploadFieldName: 'files',
      uploadFileCount: 0,
    }
  },

  computed: {
    ...mapGetters({
      pages: 'content/pages',
      uploadedFiles: 'content/uploadedFiles',
    }),

    currentFile() {
      return this.$route.params.file
    },

    currentPath() {
      return this.$route.params.path
    },

    easymde() {
      return this.$refs.markdownEditor.easymde
    },
  },

  watch: {
    '$route.query.lang'(lang) {
      this.checkForExistingFile(lang)
    },

    selectedPageId(id) {
      this.resetPage()

      if (id !== '') {
        this.getPageById(id).then(result => {
          this.page = this.deepMerge(this.page, result)
          this.selectedLanguage = this.page.lang || DEFAULT_TEMPLATE_LANG
          this.editMode = true
          this.fetchUploadedImages(this.page.id)
          this.easymde.value(this.page.content)
        })
      }

      if (this.mode === 'edit') {
        this.page.name = this.currentFile
      }

      this.loading = false
    },

    'page.info.title'() {
      if (!this.editMode && this.mode === 'new') {
        const slugify = require('slugify')
        this.page.name = slugify(
          this.page.info.title.trim().substr(0, 50),
          {
            lower: true,
            replacement: '-',
            strict: true,
            locale: this.selectedLanguage || this.page.lang || DEFAULT_TEMPLATE_LANG,
          },
        )
      }
    },

    mode(val) {
      if (val === 'new') {
        this.resetPage()
        this.selectedLanguage = DEFAULT_TEMPLATE_LANG
      }
    },
  },

  async created() {
    this.schema = await this.getSchemaFromFolder(this.currentPath)
    this.setPageProperties(this.schema)

    if (this.selectedPageId !== '') {
      this.getPageById(this.selectedPageId).then(result => {
        this.page = this.deepMerge(this.page, result)
        this.editMode = true
        this.selectedLanguage = this.page.lang || DEFAULT_TEMPLATE_LANG
        this.fetchUploadedImages(this.page.id)
        this.loading = false
      })

      return
    }

    this.selectedLanguage = this.$route.query.lang || DEFAULT_TEMPLATE_LANG
    this.checkForExistingFile(this.selectedLanguage)
    this.loading = false
  },

  methods: {
    ...mapActions({
      createPage: 'content/createPage',
      deletePageFromFolder: 'content/deletePage',
      deleteFile: 'content/deleteUploadedFile',
      editPage: 'content/editPage',
      fetchUploadedFiles: 'content/fetchUploadedFiles',
      getPageById: 'content/getPageById',
      getSchemaFromFolder: 'content/getSchemaFromFolder',
      uploadFile: 'content/uploadFile',
    }),

    addPageProperty(pageObj, props) {
      props.forEach(prop => {
        let val

        switch (prop.type) {
        case 'object':
          val = {}
          break
        case 'array':
          val = []
          break
        default:
          val = ''
        }

        this.$set(pageObj, prop.id, val)

        if (prop.properties) {
          this.addPageProperty(pageObj[prop.id], [...prop.properties])
        }
      })
    },

    checkForExistingFile(lang) {
      const filename = this.$route.path
      const pageId = lang === DEFAULT_TEMPLATE_LANG ? [filename, 'md'].join('.') : [filename, lang, 'md'].join('.')

      // workaround bc of timing issue - otherwise pages not set
      setTimeout(() => {
        const pageExists = this.pages.some(page => page.id === pageId)
        this.$emit('selected-page-id', pageExists ? pageId : '')
      }, 200)
    },

    deepMerge() {
      const extendedObj = {}

      const _merge = obj => {
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
              extendedObj[prop] = this.deepMerge(extendedObj[prop], obj[prop])
            } else {
              extendedObj[prop] = obj[prop]
            }
          }
        }
      }

      for (let i = 0; i < arguments.length; i++) {
        _merge(arguments[i])
      }

      return extendedObj
    },

    deleteImage(uri) {
      this.$bvModal.msgBoxConfirm(this.$t('dialog.delete_message'), {
        title: this.$t('dialog.delete_title'),
        okVariant: 'danger',
        okTitle: this.$t('dialog.confirm'),
        cancelTitle: this.$t('dialog.cancel'),
        centered: true,
        size: 'sm',
      })
        .then(value => {
          if (value === true) {
            this.deleteFile(uri).then(() => {
              this.$bvToast.toast(this.$t('notification.success.delete_file'), {
                title: this.$t('notification.title.success'),
                ...this.$toastSuccessConfig,
              })
              this.fetchUploadedImages(this.page.id)
            })
              .catch(error => {
                this.$bvToast.toast(this.$t('notification.error.delete_file', { error }), {
                  title: this.$t('notification.title.error'),
                  ...this.$toastErrorConfig,
                })
              })
          }
        })
    },

    deletePage(pageId) {
      this.$bvModal.msgBoxConfirm(this.$t('dialog.delete_message'), {
        title: this.$t('dialog.delete_title'),
        okVariant: 'danger',
        okTitle: this.$t('dialog.confirm'),
        cancelTitle: this.$t('dialog.cancel'),
        centered: true,
        size: 'sm',
      })
        .then(value => {
          if (value) {
            this.deletePageFromFolder(pageId).then(() => {
              this.$emit('page-deleted')
            })
              .catch(error => {
                this.$bvToast.toast(this.$t('notification.error.delete_page', { error }), {
                  title: this.$t('notification.title.error'),
                  ...this.$toastErrorConfig,
                })
              })
          }
        })
    },

    fetchUploadedImages(pageId) {
      this.fetchUploadedFiles(pageId)
        .catch(error => {
          this.$bvToast.toast(this.$t('notification.error.fetch_files', { error }), {
            title: this.$t('notification.title.error'),
            ...this.$toastErrorConfig,
          })
        })
    },

    filesAppendFormData(fieldName, fileList) {
      if (!fileList.length) {
        return
      }
      this.uploadFileCount = fileList.length

      const formData = new FormData()

      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name)
        })

      formData.append('id', this.page.id)
      this.saveUploadedImage(formData)
    },

    handleSubmit() {
      if (!this.$refs.customForm.checkValidity()) {
        return false
      }

      const data = this.page
      data.path = this.currentPath
      data.lang = this.selectedLanguage

      if (this.editMode) {
        this.editPage(data).then(() => {
          this.$bvToast.toast(this.$t('notification.success.edit_page'), {
            title: this.$t('notification.title.success'),
            ...this.$toastSuccessConfig,
          })
        })
          .catch(error => {
            this.$bvToast.toast(this.$t('notification.error.edit_page', { error }), {
              title: this.$t('notification.title.error'),
              ...this.$toastErrorConfig,
            })
          })

        return
      }

      this.createPage(data).then(() => {
        const index = this.pages.findIndex(page => page.name === data.name)
        this.$emit('page-created', this.pages[index])
      })
        .catch(error => {
          this.$bvToast.toast(this.$t('notification.error.create_page', { error }), {
            title: this.$t('notification.title.error'),
            ...this.$toastErrorConfig,
          })
        })
    },

    insertImageTag(item) {
      const cm = this.easymde.codemirror
      const stat = this.easymde.getState(cm)
      const image = item.uri
      const thumbnail = item.thumbnail ? `thumb="${item.thumbnail}"` : ''
      const text = [
        `{{< fig src="${item.uri}" ${thumbnail} title="" alt="" link="" >}}`,
        '',
      ]

      this._replaceSelection(cm, stat.image, text, image)
    },

    _replaceSelection(cm, active, startEnd, url) {
      const previewActive = /editor-preview-active/.test(cm.getWrapperElement().lastChild.className)

      if (previewActive) {
        return
      }

      let text
      let start = startEnd[0]
      let end = startEnd[1]
      const startPoint = {}
      const endPoint = {}

      Object.assign(startPoint, cm.getCursor('start'))
      Object.assign(endPoint, cm.getCursor('end'))

      if (url) {
        start = start.replace('#url#', url)
        end = end.replace('#url#', url)
      }
      if (active) {
        text = cm.getLine(startPoint.line)
        start = text.slice(0, startPoint.ch)
        end = text.slice(startPoint.ch)
        cm.replaceRange(start + end, {
          line: startPoint.line,
          ch: 0,
        })
      } else {
        text = cm.getSelection()
        cm.replaceSelection(start + text + end)
        startPoint.ch += start.length

        if (startPoint !== endPoint) {
          endPoint.ch += start.length
        }
      }
      cm.setSelection(startPoint, endPoint)
      cm.focus()

      this.$bvToast.toast(this.$t('notification.success.image_added'), {
        title: this.$t('notification.title.success'),
        ...this.$toastSuccessConfig,
      })
    },

    resetPage() {
      this.easymde.value('')
      this.page = {
        id: '',
        name: '',
        lang: '',
        content: '',
        post: true,
        info: {},
      }
      this.setPageProperties(this.schema)
      this.editMode = false
    },

    saveUploadedImage(formData) {
      // upload data to the server
      this.isSaving = true

      this.uploadFile(formData)
        .then(() => {
          const refresh = () => this.fetchUploadedImages(this.page.id)
          window.setTimeout(refresh, 1000)
        })
        .catch(error => {
          this.$bvToast.toast(this.$t('notification.error.upload_file', { error }), {
            title: this.$t('notification.title.error'),
            ...this.$toastErrorConfig,
          })
        })
        .finally(() => this.isSaving = false)
    },

    setNestedKeyValue(obj, path, value) {
      if (path.length === 1) {
        return this.$set(obj, path[0], value)
      }
      return this.setNestedKeyValue(obj[path[0]], path.slice(1), value)
    },

    setPageProperties(schema) {
      const props = schema.properties || schema
      this.addPageProperty(this.page.info, props)
    },

    updateValue(key, value) {
      const pathArray = key.split('.')
      this.setNestedKeyValue(this.page.info, pathArray, value)
    },
  },
}
</script>

<style lang="scss" scoped>
.dropbox {
  align-items: center;
  background: lightcyan;
  color: dimgray;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 150px;
  outline: 2px dashed grey;
  outline-offset: -10px;
  padding: 10px 10px;
  position: relative;

  &:hover {
    background: lightblue;
  }

  .input-file {
    cursor: pointer;
    height: 150px;
    opacity: 0; /* invisible but it's there! */
    position: absolute;
    width: 100%;
  }
}

.upload-files {
  li {
    margin: 0 2em 2em 0;
    position: relative;
  }

  img {
    max-width: 150px;
  }

  .image-delete {
    position: absolute;
    right: -10px;
    top: -10px;
  }
}
</style>
