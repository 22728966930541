import Vue from 'vue'
import VueRouter from 'vue-router'

import DashBoard from '@/components/DashBoard.vue'
import LoginPage from '@/components/LoginPage.vue'
import PagesOverview from '@/components/pages/Overview.vue'
import UserOverview from '@/components/user/Overview.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: DashBoard,
      name: 'DashBoard',
    },
    {
      path: '/login',
      component: LoginPage,
      name: 'LoginPage',
    },
    {
      path: '/user',
      component: UserOverview,
      name: 'UserOverview',
      children: [
        {
          path: ':path',
          component: UserOverview,
          name: 'UserOverview',
        },
      ],
    },
    {
      path: '/:path',
      component: PagesOverview,
      name: 'PagesOverview',
      props: true,
      children: [
        {
          path: ':file?',
          component: PagesOverview,
          name: 'PagesOverview',
        },
      ],
    },
    { path: '*', redirect: '/' },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    return { x: 0, y: 0 }
  },
})

export default router
