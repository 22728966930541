<template>
  <div class="container">
    <h2 class="text-muted text-center m-4">
      {{ $t('dashboard') }}
    </h2>

    <b-card-group deck>
      <b-card :header="$t('pages')">
        <b-card-text v-if="loading">
          <div class="text-center">
            <b-spinner variant="primary" />
          </div>
        </b-card-text>

        <b-card-text v-else>
          <b-nav
            vertical
            class="page_list"
          >
            <b-nav-item
              v-for="folder in folders"
              :key="folder.id"
              :to="{ name: 'PagesOverview', params: { path: folder.id.replace(/^\//, '') }}"
            >
              {{ folder.name }}
            </b-nav-item>
          </b-nav>
        </b-card-text>
      </b-card>

      <b-card :header="$t('user')">
        <b-card-text>
          <b-nav vertical>
            <b-nav-item :to="{ name: 'UserOverview', params: { path: 'settings' }}">
              {{ $t('account_settings') }}
            </b-nav-item>
            <b-nav-item :to="{ name: 'UserOverview', params: { path: 'change-password' }}">
              {{ $t('change_password') }}
            </b-nav-item>
          </b-nav>
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DashBoard',

  data() {
    return {
      loading: true,
    }
  },

  computed: {
    ...mapGetters({
      folders: 'content/folders',
    }),
  },

  created() {
    this.fetchFolders().then(() => this.loading = false)
  },

  methods: {
    ...mapActions({
      fetchFolders: 'content/fetchFolders',
    }),
  },
}
</script>

<style lang="scss">
.page_list .nav-link {
  text-transform: capitalize;
}
</style>
