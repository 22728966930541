<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="dark"
  >
    <b-navbar-brand :to="{ name: 'Dashboard' }">
      <img
        :src="$logoURL"
        alt="Logo"
        width="50"
        class="mr-3"
      >
      {{ $t('cms_title') }}
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" />

    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav>
        <b-nav-item-dropdown
          v-if="folders.length > 0"
          :text="$t('pages')"
        >
          <b-dropdown-item
            v-for="folder in folders"
            :key="folder.id"
            :active="$route.params.path === folder.id.replace(/^\//, '')"
            @click="selectFolder(folder)"
          >
            {{ folder.name }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template slot="button-content">
            <b-icon
              icon="globe"
              class="mr-1"
            />
            {{ $i18n.locale.toUpperCase() }}
          </template>
          <b-dropdown-item
            v-for="lang in languages()"
            :key="lang.iso"
            :active="$i18n.locale === lang.iso"
            @click="switchLanguage(lang.iso)"
          >
            {{ lang.text }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          :text="$t('user')"
          right
        >
          <b-dropdown-item :to="{ name: 'UserOverview', params: { path: 'settings' }}">
            {{ $t('account_settings') }}
          </b-dropdown-item>
          <b-dropdown-item @click="logOut">
            {{ $t('logout') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { deleteCookie } from '../../../utilities/cookieHandler'

export default {
  name: 'NavBar',

  computed: {
    ...mapGetters({
      folders: 'content/folders',
    }),
  },

  created() {
    this.fetchFolders()
  },

  methods: {
    ...mapActions({
      fetchFolders: 'content/fetchFolders',
      logoutAction: 'auth/logOut',
    }),

    selectFolder(folder) {
      this.$router.push({ name: 'PagesOverview', params: { path: folder.id.replace(/^\//, '') } })
    },

    languages() {
      return this.$languages.available.map(lang => ({
        iso: lang,
        text: this.$languages.names[lang],
      }))
    },

    logOut() {
      this.logoutAction().then(() => {
        deleteCookie('auth')
        this.$router.push({ name: 'Login' })
      })
    },

    switchLanguage(language) {
      this.$localStorageAvailable && localStorage.setItem('language', language)
      this.$i18n.locale = language
    },
  },
}
</script>

<style lang="scss" scoped>
:deep() .dropdown-item {
  text-transform: capitalize;
}

:deep() .nav-link .b-icon.bi {
  vertical-align: text-top;
}
</style>
