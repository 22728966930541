<template>
  <div class="container my-4 mt-3">
    <b-card-group
        deck
        class="mb-3"
        >
        <b-card
            bg-variant="light"
            class="mb-3"
            >
            <h4 slot="header">
              {{ $t('login_title') }}
            </h4>
          <b-alert
              variant="danger"
              :show="error"
              >
              {{ $t('error.forbidden') }}
          </b-alert>
            <b-form @submit.stop.prevent="onSubmit">
              <b-form-group
                  id="input-group-1"
                  :label="$t('email')"
                  label-for="input-email"
                  >
                  <b-form-input
                      id="input-email"
                      v-model="form.email"
                      type="email"
                      required
                      />
              </b-form-group>
                <b-form-group
                    id="input-group-2"
                    :label="$t('password')"
                    label-for="input-password"
                    >
                    <b-form-input
                        id="input-password"
                        v-model="form.password"
                        type="password"
                        required
                        />
                </b-form-group>

                  <b-button
                      type="submit"
                      variant="primary"
                      >
                      {{ $t('login') }}
                  </b-button>
                </b-form>
        </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginPage',

  props: {
    error: {
      'type': Boolean,
      'default': false,
    },
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    }
  },

  created() {
    this.loggedIn().then(() => {
      console.log('log in the user')
    })
  },

  methods: {
    ...mapActions({
      logInUser: 'auth/logIn',
      loggedIn: 'auth/loggedIn',
    }),

    onSubmit() {
      this.logInUser({ user: this.form.email, password: this.form.password }).then(() => {
        this.$router.push({ name: 'Dashboard' })
      })
    },
  },
}
</script>
