<template>
  <div class="row no-gutters">
    <div class="col-12 col-md-3 p-3">
      <b-card
        :header="$t('account')"
        no-body
      >
        <b-card-text>
          <b-nav vertical>
            <b-nav-item :to="{ name:'UserOverview', params: { path: 'settings' } }">
              {{ $t('account_settings') }}
            </b-nav-item>
            <b-nav-item :to="{ name:'UserOverview', params: { path: 'change-password' } }">
              {{ $t('change_password') }}
            </b-nav-item>
          </b-nav>
        </b-card-text>
      </b-card>
    </div>
    <div class="col-12 col-md-9 p-3">
      <b-card no-body>
        <b-tabs card>
          <b-tab
            :title="$t('account_settings')"
            :active="path === 'settings'"
            @click="changeRoute('settings')"
          >
            <b-card-text>
              <div class="row no-gutters">
                <div class="col-12 col-lg-6">
                  {{ $t('account_settings') }}
                </div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab
            :title="$t('change_password')"
            :active="path === 'change-password'"
            @click="changeRoute('change-password')"
          >
            <b-card-text>
              <div class="row no-gutters">
                <div class="col-12 col-lg-6">
                  <ChangePassword />
                </div>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import ChangePassword from './ChangePassword.vue'

export default {
  name: 'UserOverview',

  components: {
    ChangePassword,
  },

  computed: {
    path() {
      return this.$route.params.path
    },
  },

  methods: {
    changeRoute(path) {
      this.$router.push({ name: 'UserOverview', params: { path } })
    },
  },
}
</script>
<style lang="scss" scoped>
  a.nav-link.router-link-exact-active.router-link-active {
    background-color: #08d;
    color: #fff;
  }
</style>
