import axios from 'axios'

export default {
  state: {
    user: null,
    isLoggedIn: false,
  },

  getters: {
    isAuthenticated: state => Boolean(state.user),
    isLoggedIn: state => Boolean(state.isLoggedIn),
    StateUser: state => state.user,
  },

  actions: {
    changePassword(_, password) {
      return new Promise((resolve, reject) => {
        axios.post('/changePassword', { password }, { responseType: 'json' }).then(() => {
          resolve(true)
        }).catch(error => {
          console.log('Change password', error)
          reject(error)
        })
      })
    },

    loggedIn({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post('/loggedIn', null, { responseType: 'json' }).then(response => {
          console.info('loggedIn', response)
          commit('setUser', { username: response.Message })
          resolve(true)
        }).catch(error => {
          console.error('Not logged in', error)
          resolve(true)
        })
      })
    },

    logIn({ commit }, UserData) {
      return new Promise((resolve, reject) => {
        axios.post('/login', UserData, { responseType: 'json' }).then(response => {
          document.cookie = `auth=${response.data.success}`
          commit('setUser', { username: UserData.user })
          resolve(true)
        }).catch(error => {
          console.log('Login', error)
          reject(error)
        })
      })
    },

    logOut({ commit }) {
      commit('logOutUser')
    },
  },

  mutations: {
    setUser(state, UserData) {
      state.user = UserData.username
      state.isLoggedIn = true
    },

    logOutUser(state) {
      state.user = null
      state.isLoggedIn = false
    },
  },

  namespaced: true,
}
