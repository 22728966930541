<template>
  <div
    v-if="formFields.length > 0"
    class="mb-4 form-row"
  >
    <template v-for="field in formFields">
      <b-form-group
        :key="field.id"
        :label="field.label"
        :label-for="field.id"
        :class="['position-relative', 'col-12', field.class ? field.class : '']"
        label-class="font-weight-bold"
      >
        <template v-if="field.type === 'array'">
          <template v-if="field.items.type === 'string'">
            <b-form-tags
              v-model="fieldData[field.id]"
              :placeholder="field.placeholder"
              add-button-text="+"
              remove-on-delete
              @input="emitValue(`${field.id}`, $event)"
            />
          </template>

          <template v-else-if="field.items.type === 'object'">
            <b-button
              v-b-toggle="`collapse-fields-${field.id}`"
              variant="outline-secondary"
              size="sm"
              class="toggle"
              @click="showFields = !showFields"
            >
              <b-icon :icon="showFields ? 'chevron-down' : 'chevron-up'" />
            </b-button>
            <b-collapse
              :id="`collapse-fields-${field.id}`"
              class="pt-3"
            >
              <template v-if="fieldData[field.id].length === 0">
                <p class="p-4 text-sm-center">
                  {{ $t('page_editor.add_form_fields_hint') }}
                </p>
              </template>
              <template v-else>
                <template v-for="(dataObj, index) in fieldData[field.id]">
                  <b-container
                    :key="index"
                    class="mb-5"
                  >
                    <b-form-row>
                      <b-col align-self="center">
                        <b-form-checkbox
                          :value="fieldData[field.id][index]"
                          :data-index="index"
                        />
                      </b-col>
                      <b-col cols="11">
                        <template v-for="nestedField in getProperties(field.items)">
                          <b-form-group
                            :key="nestedField.id"
                            :label="nestedField.label"
                            :label-for="nestedField.id"
                            :placeholder="nestedField.placeholder"
                            label-cols-sm="3"
                            label-align-sm="right"
                          >
                            <b-form-input
                              :id="nestedField.id"
                              v-model="fieldData[field.id][index][nestedField.id]"
                              :required="nestedField.required"
                              :will-validate="nestedField.required"
                              @update="emitValue(`${field.id}.${index}.${nestedField.id}`, $event)"
                            />
                          </b-form-group>
                        </template>
                      </b-col>
                    </b-form-row>
                  </b-container>
                </template>
              </template>
              <div class="d-flex justify-content-between">
                <b-button
                  :disabled="fieldData[field.id].length === 0"
                  size="sm"
                  variant="danger"
                  @click="deleteEntries(field.id)"
                >
                  <b-icon-trash /> {{ $t('page_editor.delete_selection') }}
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  class="ml-2"
                  @click="addEntries(field.id)"
                >
                  <b-icon-plus /> {{ $t('page_editor.add_form_fields') }}
                </b-button>
              </div>
            </b-collapse>
          </template>
        </template>

        <template v-else-if="field.type === 'object'">
          <template v-for="nestedField in getProperties(field)">
            <b-form-group
              :key="nestedField.id"
              :label="nestedField.label"
              :label-for="nestedField.id"
              :placeholder="nestedField.placeholder"
              :required="nestedField.required"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                :id="nestedField.id"
                v-model="fieldData[field.id][nestedField.id]"
                @update="emitValue(`${field.id}.${nestedField.id}`, $event)"
              />
            </b-form-group>
          </template>
        </template>

        <template v-else>
          <b-form-input
            :id="field.id"
            v-model="fieldData[field.id]"
            :placeholder="field.placeholder"
            :required="field.required"
            :will-validate="field.required"
            @update="emitValue(`${field.id}`, $event)"
          />
        </template>
      </b-form-group>
    </template>
  </div>
</template>


<script>
export default {
  name: 'CustomForm',

  props: {
    schema: {
      'type': Object,
      'default': null,
    },

    formData: {
      'type': Object,
      'default': null,
    },

    mode: {
      'type': String,
      'default': 'new',
      'validator' (value) {
        return ['new', 'edit'].indexOf(value) !== -1
      },
    },
  },

  data() {
    return {
      formFields: [],
      fieldData: {},
      showFields: false,
    }
  },

  watch: {
    schema(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.formFields = this.getProperties({ ...newVal })
      }
    },

    formData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fieldData = newVal
      }
    },
  },

  created() {
    if (this.schema) {
      this.formFields = this.getProperties(this.schema)
    }
    if (this.formData) {
      this.fieldData = this.formData
    }
  },

  methods: {
    addEntries(id) {
      this.$set(this.fieldData[id], this.fieldData[id].length, {})
      this.emitValue(id, this.fieldData[id])
    },

    deleteEntries(id) {
      const indices = []
      const entries = Array.from(document.querySelectorAll(`#collapse-fields-${id} input[type=checkbox]:checked`))

      if (entries.length === 0) {
        return
      }

      entries.forEach(entry => indices.push(entry.dataset.index))
      indices.forEach(index => this.fieldData[id].splice(index))

      this.emitValue(id, this.fieldData[id])
    },

    emitValue(propName, value) {
      this.$emit(`update`, propName, value)
    },

    getProperties(schema) {
      const properties = []
      const props = schema.properties || schema

      props.forEach(prop => {
        properties.push(prop)
      })

      return properties
    },
  },
}
</script>

<style lang="scss" scoped>
.btn.toggle {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
